import "./variables.css";
import "./App.css";

import { HomePage } from "./pages";

function App() {
    return (
        <div className="App">
            <HomePage />
        </div>
    );
}

export default App;
